import { type KeyEventUpdateParams } from 'types/key_event';
import { patchRequest } from 'utils/rest-api-util';

export const patchRegisteredKeyEvent = async (
  analyticId: string,
  keyEventId: string,
  keyEventParams: KeyEventUpdateParams,
): Promise<Response> => {
  const response = await patchRequest(
    `/api/v1/analytics/${analyticId}/registered_key_events/${keyEventId}`,
    keyEventParams,
  );

  return response;
};
